import { Link } from "gatsby";
import React from "react";
import Button from "./Button";
import {
  InstagramLogo,
  FacebookLogo,
  Envelope,
  TelegramLogo,
  WhatsappLogo,
} from "@phosphor-icons/react";
import clsx from "clsx";

const navlinks = [
  {
    name: "Beranda",
    link: "/",
  },
  // {
  //   name: "Paket Internet",
  //   link: "/paket-internet",
  // },
  // {
  //   name: "Layanan",
  //   link: "/layanan",
  // },
  {
    name: "Cakupan Area",
    link: "#area",
  },
  {
    name: "Tentang Kami",
    link: "#about",
  },
];

const navIcons = [
  {
    link: "https://www.instagram.com/intanet.id/",
    icon: <InstagramLogo size={20} />,
  },
  {
    link: "https://www.facebook.com/intanet.id/",
    icon: <FacebookLogo size={20} />,
  },
  {
    link: "https://web.telegram.org/#/login",
    icon: <TelegramLogo size={20} />,
  },
  {
    link: "https://wa.me/6282242457777",
    icon: <WhatsappLogo size={20} />,
  },
  {
    link: "mailto:cs@intanet.id",
    icon: <Envelope size={20} />,
    email: "cs@intanet.id",
  },
];

const NavbarComponent = () => {
  const [active, setActive] = React.useState<string>("Beranda");

  return (
    <>
      <div className="container">
        <ul className="lg:flex justify-end hidden gap-2 p-3">
          {navIcons.map((data, index) => (
            <a
              key={index}
              href={data.link}
              target="_blank"
              className="flex gap-2 items-center text-gray-600 hover:text-primary transition-colors"
            >
              {data.icon}
              <span>{data.email}</span>
            </a>
          ))}
        </ul>
      </div>
      <nav className="w-full font-medium sticky top-0 z-10 bg-white/40 backdrop-blur-md py-3">
        <div className="container">
          <div className="w-full flex items-center justify-center lg:justify-between py-3">
            <Link to="/">
              <img
                src={"/images/intanet-text.png"}
                alt="logo-intanet"
                className="w-40"
              />
            </Link>
            <div className="ml-10 lg:flex gap-10 hidden">
              <ul className="flex gap-5 items-center">
                {navlinks.map((data, index) => (
                  <Link
                    key={index}
                    to={data.link}
                    onClick={() => setActive(data.name)}
                    className={clsx(
                      "hover:bg-secondary px-5 py-1 text-center rounded-md transition-colors duration-300 hover:text-gray-800",
                      active === data.name
                        ? "bg-secondary font-medium"
                        : "text-gray-500"
                    )}
                  >
                    {data.name}
                  </Link>
                ))}
              </ul>
              {/* <Button
            text="Hubungi Kami"
            isPrimary={true}
            isOutline={false}
            onClick={() => console.log("Hello")}
          /> */}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarComponent;
