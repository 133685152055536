import * as React from "react";
import NavbarComponent from "./Navbar";

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <main className="min-h-screen w-full bg-white text-base font-roboto relative">
      <NavbarComponent />
      <div className="w-full mb-10">{children}</div>
    </main>
  );
}
